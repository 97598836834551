import React from 'react';
import tripadvisor from '../images/Icon-tripadvisor-72x72.webp';
import vrbo from '../images/Icon-Vrbo-72x72.webp';
import booking from '../images/Icon-booking-72x72.webp';
import airbnb from '../images/Icon-airbnb-72x72.webp';
import { langFiles as languageFiles } from './LanguageSwitcher';

function Footer({ language }) {
    const content = languageFiles[language];
    
    return (
        <footer>
            <div className='book-buttons-bar'>
                <div className='icons'>
                    <div className='title-booknow'><h4>{content.booknow}</h4></div>
                    <a className="booklink" href="https://www.tripadvisor.com/VacationRentalReview-g12791748-d25575354-4BR_Villa_with_private_Pool_in_Kutuh-Kutuh_Bali.html" target="_blank" rel="noopener noreferrer"><img src={tripadvisor} alt="tripadvisor" width={48} height={48} /></a>
                    <a className="booklink" href="https://www.vrbo.com/3395544" target="_blank" rel="noopener noreferrer"><img src={vrbo} alt="vrbo" width={48} height={48} /></a>
                    <a className="booklink" href="https://www.booking.com/hotel/id/4br-villa-with-private-pool-kabupaten-badung.nl.html" target="_blank" rel="noopener noreferrer"><img src={booking} alt="booking.com" width={48} height={48} /></a>
                    <a className="booklink" href="http://airbnb.com/h/villa-kroeze" target="_blank" rel="noopener noreferrer"><img src={airbnb} alt="airbnb" width={48} height={48} /></a>
                </div>
            </div>
            <p className='copyright-text'>&copy; 2023 Villa Kroeze. {content.copyrightText}</p>
        </footer>
    );
}

export default Footer;