import React from "react";
import PhotoLightbox from '../parts/PhotoLightbox';
import { langFiles as languageFiles } from '../parts/LanguageSwitcher';
import photo1 from '../images/photo-lumbung.webp';
import photo2 from '../images/photo-woonkamer.webp';
import photo3 from '../images/photo-badkamer.webp';
import photo4 from '../images/photo-zwembad-1.webp';
import photo5 from '../images/photo-zwembad-2.webp';
import photo6 from '../images/photo-zwembad-3.webp';
import photo7 from '../images/photo-zwembad-4.webp';

function Photos({language}) {

  const content = languageFiles[language];

  const photos = [
    { id: 1, src: photo1, title: content.lumbung },
    { id: 2, src: photo2, title: content.woonkamer },
    { id: 3, src: photo3, title: content.badkamer },
    { id: 4, src: photo4, title: content.zwembad },
    { id: 5, src: photo5, title: content.zwembad },
    { id: 6, src: photo6, title: content.zwembad },
    { id: 7, src: photo7, title: content.zwembad },
  ];

  return (
    <section className="villa-photos">
      <h2><span>{content.photos}</span></h2>
      <div className="thumbnails">
        {photos.map(photo => (
          <PhotoLightbox key={photo.id} image={photo} />
        ))}
      </div> 
    </section>
  );
};


export default Photos;