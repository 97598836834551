import { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import nlFlag from '../locales/flags/4x3/nl.svg';
import gbFlag from '../locales/flags/4x3/gb.svg';
import frFlag from '../locales/flags/4x3/fr.svg';
import deFlag from '../locales/flags/4x3/de.svg';
import esFlag from '../locales/flags/4x3/es.svg';
import idFlag from '../locales/flags/4x3/id.svg';
import jpFlag from '../locales/flags/4x3/jp.svg';
import krFlag from '../locales/flags/4x3/kr.svg';
import twFlag from '../locales/flags/4x3/tw.svg';
import seFlag from '../locales/flags/4x3/se.svg';
import ptFlag from '../locales/flags/4x3/pt.svg';
import nl from '../locales/langFiles/nl.json';
import en from '../locales/langFiles/en.json';
import fr from '../locales/langFiles/fr.json';
import de from '../locales/langFiles/de.json';
import es from '../locales/langFiles/es.json';
import id from '../locales/langFiles/id.json';
import ja from '../locales/langFiles/ja.json';
import kr from '../locales/langFiles/kr.json';
import zh from '../locales/langFiles/zh-TW.json';
import sv from '../locales/langFiles/sv.json';
import pt from '../locales/langFiles/pt.json';

export const langFiles = {
  nl,
  en,
  fr,
  de,
  es,
  id,
  ja,
  kr,
  zh,
  sv,
  pt,
};

export const languageFiles = ['nl', 'en', 'fr', 'de', 'es', 'id', 'ja', 'kr', 'zh', 'sv', 'pt'];

const languageOptions = [
  { value: 'nl', label: 'Nederlands', flag: nlFlag },
  { value: 'en', label: 'English', flag: gbFlag },
  { value: 'fr', label: 'Français', flag: frFlag },
  { value: 'de', label: 'Deutsch', flag: deFlag },
  { value: 'es', label: 'Español', flag: esFlag },
  { value: 'id', label: 'Bahasa Indonesia', flag: idFlag },
  { value: 'ja', label: '日本語', flag: jpFlag },
  { value: 'kr', label: '한국어', flag: krFlag },
  { value: 'zh', label: '繁體中文', flag: twFlag },
  { value: 'sv', label: 'Svenska', flag: seFlag },
  { value: 'pt', label: 'Português', flag: ptFlag }
];

languageOptions.sort((a, b) => (a.value > b.value) ? 1 : -1);


export const getBrowserLanguage = () => {
  const browserLanguage = navigator.language.split('-')[0];
  return languageFiles.includes(browserLanguage) ? browserLanguage : 'nl';
};

function LanguageSwitcher({ language }) {

  const location = useLocation();

  const navigate = useNavigate();

  const [currentLanguage, setCurrentLanguage] = useState(getBrowserLanguage());

  const currentLanguageTop = languageOptions.find(option => option.value === currentLanguage);

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
    const newPathname = language === 'nl' ? `/${location.pathname.split('/')[2] ? location.pathname.split('/')[2] : ''}` : `/${language}/${location.pathname.split('/')[2] ? location.pathname.split('/')[2] : ''}`;
    navigate(newPathname);
  };


  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const languageCode = languageFiles.includes(pathSegments[1]) ? pathSegments[1] : getBrowserLanguage() || getBrowserLanguage();
    setCurrentLanguage(languageCode);
  }, [location]);

  useEffect(() => {
    if (languageFiles.includes(currentLanguage)) {
      const pathSegments = location.pathname.split('/');
      if ((!pathSegments[1] || !languageFiles.includes(pathSegments[1])) && currentLanguage !== 'nl') {
        const newPathname = `/${currentLanguage}`;
        navigate(newPathname);
      }
    }
  }, [currentLanguage, location, navigate]);

  return (
    <div className="language-switcher">
      <button className={`button${isOpen ? ' hide' : ''}`} onClick={handleToggle} aria-haspopup="true" aria-controls="dropdown-menu">
        <span>{currentLanguageTop.label}</span> <span className="icon is-small"><img src={currentLanguageTop.flag} alt={currentLanguageTop.label} /> ▼</span>
      </button>
      <div className={`dropdown-menu${isOpen ? ' open' : ''}`} id="dropdown-menu" role="menu">
        {languageOptions.map(option => (
          <div
            key={option.value}
            className={`dropdown-item${option.value === language ? ' is-active' : ''}`}
            role='menuitem'
            onClick={() => { handleToggle(); handleLanguageChange(option.value) }}
          >
            <span>{option.label}</span>
            <span className="icon is-small">
              <img src={option.flag} alt={option.label} />
            </span>
          </div>
        ))}
      </div>
    </div>

  );
}

export default LanguageSwitcher;
