import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './assets/parts/Header';
import Main from './assets/parts/Main';
import Footer from './assets/parts/Footer';
import { getBrowserLanguage, languageFiles } from './assets/parts/LanguageSwitcher';

const LanguagePage = () => {
  const location = useLocation();
  const [language, setLanguage] = useState( getBrowserLanguage() );
  const pathSegments = location.pathname.split('/');
  const languageCode = languageFiles.includes(pathSegments[1]) ? pathSegments[1] : getBrowserLanguage() || getBrowserLanguage();

  useEffect(() => {
    setLanguage(languageCode);
  }, [languageCode]);

  return (
    <>
      <Header language={language} />
      <Main language={language} />
      <Footer language={language} />
    </>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<LanguagePage />} />
        <Route path="/:lang/*" element={<LanguagePage />} />
      </Routes>
    </Router>
  );
}

export default App;
